import React, { useCallback, useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../utils/contexts/UserContext';
import {
  deleteRoadmap,
  getRoadmaps,
} from '../../../services/roadmapTask/roadmapService';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PlayIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import CircleProgressBar from '../../../components/charts/CircleProgressBar';
import { Button } from '../../../components/forms/Buttons/Button';
import { toast } from 'react-toastify';

const RoadmapList = () => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [t] = useTranslation();
  const [roadmaps, setRoadmaps] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const getRoadmapData = useCallback(() => {
    setLoading(true);
    getRoadmaps({
      page_size: 50,
      assignor: user?.uuid,
    })
      .then((res) => {
        setRoadmaps(res?.results);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [user?.uuid]);

  useEffect(() => {
    getRoadmapData();
  }, [getRoadmapData]);

  const colors = [
    'from-red-500 to-red-200',
    'from-violet-600 to-violet-200',
    'from-green-400 to-blue-400',
  ];

  const handleDeleteConfirmation = (uuid) => {
    toast.dark(
      <>
        <div>Are you sure you want to delete the roadmap?</div>
        <div className='flex justify-center mt-3 gap-3'>
          <Button
            text='Yes'
            color='error-solid'
            onClick={() => handleDelete(uuid)}
          />
          <Button text='No' className='text-white' onClick={toast.dismiss} />
        </div>
      </>,
      {
        autoClose: false,
        closeButton: true,
      },
    );
  };

  function handleDelete(uuid) {
    deleteRoadmap(uuid)
      .then((res) => {
        toast.info('Roadmap successfully deleted');
        getRoadmapData();
      })
      .catch((error) => {});
  }

  return (
    <div className='flex flex-row flex-wrap gap-4 p-4'>
      <div
        className='relative w-72 h-48 rounded-xl border-4 cursor-pointer
      flex flex-col justify-center text-lg items-center font-bold
      '
        onClick={() => navigate(`/roadmap/create`)}
      >
        <PlusIcon className='h-12 text-amethyst' />
        <div className='bg-gradient-to-r from-amethyst to-error bg-clip-text text-transparent'>
          {t('studyplans.create_new_plan')}
        </div>
      </div>
      {roadmaps?.map((roadmap, i) => {
        return (
          <div
            key={roadmap.uuid}
            className={`relative w-72 h-48 rounded-xl border-4 cursor-pointer
            flex flex-col justify-between text-lgfont-bold bg-gradient-to-tr group ${
              colors[i % (colors?.length - 1)]
            }`}
          >
            <TrashIcon
              className='h-8 p-1.5 hidden group-hover:block rounded-full absolute -right-3 -top-3 text-error bg-red-100'
              onClick={() => {
                handleDeleteConfirmation(roadmap.uuid);
              }}
            />
            <div className='flex flex-col h-full justify-between p-4 '>
              <div className='flex flex-col'>
                <span className='text-white text-2xl font-bold'>
                  {roadmap?.student?.first_name} {roadmap?.student?.last_name}
                </span>
                <span className='text-gray-200 text-sm font-bold'>
                  {roadmap.name}
                </span>
              </div>
              <span className='text-sm text-gray-200'>
                Author:{' '}
                {`${roadmap.assignor?.first_name} ${roadmap.assignor?.last_name}`}
              </span>
            </div>
            <div className='bg-white flex flex-row justify-between p-1 px-2'>
              <div className='flex flex-row gap-4 '>
                <div className='flex flex-col items-center -space-y-2'>
                  <span className='text-lg font-semibold pb-2'>
                    <CircleProgressBar
                      progress={(roadmap.task_count
                        ? roadmap.done_count / roadmap.task_count
                        : 0 * 100
                      ).toFixed()}
                    />
                  </span>
                  <span className='text-gray-500 text-sm'></span>
                </div>
                <div className='flex flex-col items-center -space-y-2'>
                  <span className='text-lg font-semibold'>
                    {moment(roadmap.end_date, 'YYYY-MM-DD')?.diff(
                      moment(roadmap.start_date, 'YYYY-MM-DD'),
                      'week',
                    )}
                  </span>
                  <span className='text-gray-500 text-sm'>Weeks</span>
                </div>
                <div className='flex flex-col items-center -space-y-2'>
                  <span className='text-lg font-semibold'>
                    {roadmap.task_count}
                  </span>
                  <span className='text-gray-500 text-sm'>Tasks</span>
                </div>

                <div className='flex flex-col items-center -space-y-2'>
                  <span className='text-lg font-semibold'>{roadmap.group}</span>
                  <span className='text-gray-500 text-sm'>Grade</span>
                </div>
              </div>

              <div>
                <div className='flex flex-col justify-center'>
                  <PlayIcon
                    className='h-9 rounded-full border p-1.5 hover:bg-slate-200'
                    onClick={() => {
                      navigate(`/profile/${roadmap.student.uuid}`, {
                        state: {
                          tab: 'roadmap',
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RoadmapList;
