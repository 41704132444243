import React, { useEffect, useState } from 'react';
import Select from '../../../components/forms/Select/Select';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { Button } from '../../../components/forms/Buttons/Button';
import {
  getClasses,
  getOrganizations,
} from '../../../services/catalogs/organizationService';
import { getGenerallGradeStats } from '../../../services/stats/statsService';
import { getAccountDatum } from '../../../services/accountData/accountDataService';

export default function SelectStudentPart({
  selectedStudent,
  setSelectedStudent,
  selectedStudentUuid,
  setSelectedStudentUuid,
  comment,
  setComment,
  setSelectedIndex,
  setMaxIndex,
}) {
  const [gradeList, setGradeList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [isStudentInfo, setIsStudentInfo] = useState(false);

  const [orgLoading, setOrgLoading] = useState(false);
  const [gradesLoading, setGradesLoading] = useState(false);
  const [studentsLoading, setStudentsLoading] = useState(false);

  useEffect(() => {
    setOrgLoading(true);
    getOrganizations()
      ?.then((res) => {
        const options = res?.results?.map((cl) => ({
          label: cl?.name,
          value: cl?.uuid,
        }));
        setOrgList(options);
        if (res?.count < 2) {
          setGradesLoading(true);
          getClasses({})
            .then((res) => {
              const options = res?.results?.map((cl) => ({
                label: cl?.name,
                value: cl?.uuid,
              }));
              setGradeList(options);
            })
            .finally(() => {
              setGradesLoading(false);
            });
        }
      })
      ?.finally(() => setOrgLoading(false));
  }, []);

  return (
    <div className='md:m-0 mx-4 md:min-h-[calc(100vh-16rem)] flex flex-col shadow-md rounded-md border'>
      {!isStudentInfo ? (
        <div className='flex flex-col mt-12 gap-4 text-center items-center'>
          <p className='mb-12'>
            <span className='text-red-600'>*</span>Each student can have only
            one individualized roadmap.
          </p>

          {/* <label></label> */}
          <div className='w-96'>
            {orgList?.length > 1 && (
              <Select
                className='text-left mb-4'
                label='Select school'
                loading={orgLoading}
                options={orgList}
                onChange={(e) => {
                  setGradesLoading(true);
                  getClasses({ organization: e.value })
                    .then((res) => {
                      const options = res?.results?.map((cl) => ({
                        label: cl?.name,
                        value: cl?.uuid,
                      }));
                      setGradeList(options);
                    })
                    .finally(() => {
                      setGradesLoading(false);
                    });
                }}
              />
            )}
            <Select
              className='text-left mb-4'
              label='Select grade'
              isDisabled={orgList?.length > 1 && gradeList.length === 0}
              loading={gradesLoading}
              options={gradeList}
              onChange={(e) => {
                setStudentsLoading(true);
                getGenerallGradeStats(e?.value, false)
                  .then((res) => {
                    const options = res?.students?.map((s) => ({
                      label: s?.name,
                      value: s?.uuid,
                    }));
                    setStudentList(options);
                  })
                  .finally(() => {
                    setStudentsLoading(false);
                  });
              }}
            />
            <Select
              className='text-left'
              label='Select student'
              loading={studentsLoading}
              isDisabled={studentList.length === 0}
              options={studentList}
              onChange={(e) => {
                setSelectedStudentUuid(e?.value);
              }}
            />
            <div className='flex flex-row justify-end py-8'>
              <Button
                disabled={!selectedStudentUuid}
                onClick={() => {
                  getAccountDatum(selectedStudentUuid).then((res) => {
                    setSelectedStudent(res);
                    setIsStudentInfo(true);
                  });
                }}
                text='Next'
              />
            </div>
          </div>
        </div>
      ) : (
        <div className='pt-8 px-16 flex flex-row justify-center'>
          <dl className='divide-y divide-gray-100 w-1/2 '>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Student's main information
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                <div className='grid grid-cols-6 gap-2'>
                  <div className='col-span-1'>
                    <div className='font-semibold'>Name</div>

                    <div>{selectedStudent?.first_name}</div>
                  </div>
                  <div className='col-span-5'>
                    <div className='font-semibold'>Surname</div>
                    <div>{selectedStudent?.last_name}</div>
                  </div>
                  <div className='col-span-2'>
                    <div className='font-semibold'>Hobby</div>
                    <div>
                      {!!selectedStudent?.additional_info
                        ? selectedStudent?.additional_info
                        : 'No info'}{' '}
                    </div>
                  </div>
                  <div className='col-span-4'>
                    <div className='font-semibold'>Olympiad</div>
                    <div>
                      {!!selectedStudent?.olympiad
                        ? selectedStudent?.olympiad
                        : 'No info'}{' '}
                      {/* {selectedStudent?.data?.exam_ratings?.Olympiad?.map(
                        (ol, i) => (
                          <span key={i}>{ol?.subject_name + ' '}</span>
                        ),
                      )} */}
                    </div>
                  </div>
                </div>
              </dd>
            </div>
            <div className='overflow-x-visible px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Exam Results
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 col-span-2 sm:mt-0'>
                <div className='flex flex-row gap-4'>
                  {!!selectedStudent?.data?.exam_ratings?.BTS?.length
                    ? selectedStudent?.data?.exam_ratings?.BTS?.slice(
                        0,
                        2,
                      )?.map((bts, i) => (
                        <div
                          key={i}
                          className='border rounded p-2 min-w-[15rem] max-w-[15rem]'
                        >
                          <div className='flex flex-row gap-2'>
                            <div className='font-semibold max-w-[50%] truncate'>
                              {bts?.exam_name}
                            </div>
                            <div className='text-secondary'>{bts?.date}</div>
                          </div>
                          <div>
                            <div className='flex flex-row justify-between items-end'>
                              <span>Total</span>
                              <span className='text-xs mb-0.5'>
                                {`${bts.total_correct}/${bts.total_question}`}
                              </span>
                            </div>
                            <div className='rounded-lg overflow-hidden bg-gray-300'>
                              <div
                                className='flex flex-col justify-center min-w-fit px-2 text-xs bg-yellow-500 text-white'
                                style={{
                                  width: `${bts?.total_overall * 100}%`,
                                }}
                              >
                                {(bts?.total_overall * 100)?.toFixed(0)} %
                              </div>
                            </div>
                          </div>
                          {bts?.subjects?.map((subject) => {
                            return (
                              <div>
                                <div className='flex flex-row justify-between items-end'>
                                  <span>{subject.subject_name}</span>
                                  <span className='text-xs mb-0.5'>
                                    {`${subject.total_correct}/${subject.total_question}`}
                                  </span>
                                </div>
                                <div className='rounded-lg overflow-hidden bg-gray-300'>
                                  <div
                                    className='flex flex-col justify-center min-w-fit px-2 text-xs bg-primary text-white'
                                    style={{
                                      width: `${
                                        subject?.subject_rating * 100
                                      }%`,
                                    }}
                                  >
                                    {(subject?.subject_rating * 100)?.toFixed(
                                      0,
                                    )}{' '}
                                    %
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ))
                    : 'No BTS results'}
                </div>
                <div className='flex flex-row flex-wrap gap-4 min-w-[32rem] mt-4'>
                  {!!selectedStudent?.data?.exam_ratings?.KBO?.length
                    ? selectedStudent?.data?.exam_ratings?.KBO?.map(
                        (kbo, i) => (
                          <div
                            key={i}
                            className='border rounded p-2 min-w-[15rem] max-w-[15rem]'
                          >
                            <div className='flex flex-row gap-2'>
                              <div className='font-semibold max-w-[50%] truncate'>
                                {kbo?.exam_name}
                              </div>
                              <div className='text-secondary'>{kbo?.date}</div>
                            </div>
                            <div className='flex flex-row'>
                              School Rating: {kbo.school_order}
                            </div>
                            <div className='flex flex-row'>
                              Country Rating: {kbo.absolute_order}
                            </div>

                            {kbo?.subjects?.map((subject) => {
                              return (
                                <div>
                                  <div className='flex flex-row justify-between items-end'>
                                    <span>{subject.subject_name}</span>
                                    <span className='text-xs mb-0.5'>
                                      {`${subject.total_correct}/${subject.total_question}`}
                                    </span>
                                  </div>
                                  <div className='rounded-lg overflow-hidden bg-gray-300'>
                                    <div
                                      className='flex flex-col justify-center min-w-fit px-2 text-xs bg-primary text-white'
                                      style={{
                                        width: `${
                                          subject?.subject_rating * 100
                                        }%`,
                                      }}
                                    >
                                      {(subject?.subject_rating * 100)?.toFixed(
                                        0,
                                      )}{' '}
                                      %
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ),
                      )
                    : 'No KBO results'}
                </div>
              </dd>
            </div>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Additional information
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                <TextArea
                  // label={t('accounting.comment')}
                  rows={6}
                  className='border rounded-lg shadow-md'
                  placeholder={
                    'You can write additional inforamtion about the student'
                  }
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </dd>
            </div>
            <div className='flex flex-row justify-end pb-8'>
              <Button
                disabled={!selectedStudentUuid}
                onClick={() => {
                  setSelectedIndex((v) => v + 1);
                  setMaxIndex((v) => v + 1);
                }}
                text='Next'
              />
            </div>
          </dl>
        </div>
      )}
    </div>
  );
}
