import { CheckIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/forms/Buttons/Button';
import Select from '../../components/forms/Select/Select';
import { getOrganizations } from '../../services/catalogs/organizationService';
// import StackedBarChart from './Chart';
import GeneralTable from './GeneralTable';
import Table from './Table';
import Title from './Title';

const Leaderboard = () => {
  const [t] = useTranslation();

  const [byDomain, setByDomain] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [org, setOrg] = useState();

  useEffect(() => {
    getOrganizations()
      .then((res) => {
        // console.log('orgs', res?.results);
        setOrgs(res?.results);
      })
      .catch(() => {});
  }, []);

  return (
    <div className='flex flex-col p-4 gap-4'>
      <Title />
      <div className='flex flex-row items-center md:gap-8 gap-4'>
        <div>{'Select by'}</div>
        <div className='flex flex-row md:gap-4 gap-2'>
          <Button
            text={'General'}
            color={byDomain ? '' : 'primary'}
            onClick={() => setByDomain(false)}
            Icon={byDomain ? null : CheckIcon}
            rounded
          />
          <Button
            text={'Projects'}
            color={byDomain ? 'primary' : ''}
            onClick={() => setByDomain(true)}
            Icon={byDomain ? CheckIcon : null}
            rounded
          />
        </div>
      </div>
      {orgs?.length > 1 && (
        <div className='flex flex-row items-center gap-2'>
          <p>Select school</p>
          <Select
            options={[
              { label: 'All schools', value: null },
              ...orgs.map((d) => ({ label: d?.name, value: d?.uuid })),
            ]}
            onChange={(e) => setOrg(e?.value)}
            value={org}
            className='w-full lg:w-96'
          />
        </div>
      )}

      <div className='pt-4'>
        {byDomain ? <Table org={org} /> : <GeneralTable org={org} />}
      </div>
    </div>
  );
};

export default Leaderboard;
