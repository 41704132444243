import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../components/forms/Checkbox/Checkbox';
import { patchRoadmapSubtask } from '../../services/roadmapTask/roadmapSubtaskService';
import AddSub from './BREAD/AddSub';
import Edit from './BREAD/Edit';
import EditSub from './BREAD/EditSub';
import AddSubAI from './BREAD/AddSubAI';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

const Table = ({ data, domains, refreshData, studentUuid }) => {
  const [t] = useTranslation();

  // const [isOpen, setIsOpen] = useState(new Array(data?.length).fill(false));
  const [isOpen, setIsOpen] = useState(
    new Map(data?.map((d) => [d?.uuid, false])),
  );

  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });

  return (
    <div className='rounded-lg shadow-md border pt-4'>
      <table className='table-auto w-full'>
        <thead>
          <tr className='text-gray-500 border-b'>
            <th className='border-r px-2 w-min'>{t('roadmap.type')}</th>
            <th className='text-left border-r px-2'>{t('roadmap.title')}</th>
            <th className='border-r px-2'>{t('roadmap.status')}</th>
            {!isMobile && (
              <>
                {/* <th className='border-r px-2'>{t('stats.domain')}</th> */}
                <th className='border-r px-2'>{t('roadmap.deadline')}</th>
                <th className='px-2'>{t('roadmap.progress')}</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((d, index) => (
            <Fragment key={d.uuid}>
              <tr className='border-b last:border-b-0 hover:bg-gray-100 h-10'>
                <td className='py-2 border-r px-2'>
                  <div className='flex flex-row items-center gap-1 justify-center'>
                    {isOpen.get(d?.uuid) ? (
                      <ChevronUpIcon
                        className='lg:h-4 h-3 cursor-pointer'
                        onClick={() => {
                          setIsOpen((prev) => {
                            let temp = new Map(prev);
                            temp.set(d?.uuid, false);
                            return temp;
                          });
                        }}
                      />
                    ) : (
                      <ChevronDownIcon
                        className='lg:h-4 h-3 cursor-pointer'
                        onClick={() => {
                          setIsOpen((prev) => {
                            let temp = new Map(prev);
                            temp.set(d?.uuid, true);
                            return temp;
                          });
                        }}
                      />
                    )}
                    <CalendarIcon className='lg:h-5 h-4 text-success' />
                    <SparklesIcon className='h-5 lg:invisible hidden' />
                  </div>
                </td>
                <td className='lg:text-base text-sm border-r lg:min-w-[20rem] lg:max-w-[20rem] max-w-[6rem]'>
                  <Edit
                    datum={d}
                    domains={domains}
                    refreshData={refreshData}
                    className='hover:text-primary px-2 py-1.5 h-full truncate cursor-pointer'
                  />
                </td>
                <td className='font-light lg:text-base text-sm text-center border-r px-2'>
                  {d?.status}
                </td>
                {!isMobile && (
                  <>
                    {/* <td
                      className={`lg:text-base text-xs pl-1 pr-2 w-56 border-r px-2 `}
                    >
                      <div className='flex flex-row gap-1 text-white w-full justify-center'>
                        {d?.domains?.map((dd) => (
                          <div
                            key={dd?.uuid}
                            className={`${
                              dd?.name === 'Academic Domain'
                                ? 'bg-success'
                                : dd?.name === 'Career Domain'
                                ? 'bg-amethyst'
                                : dd?.name === 'Social Emotional Domain'
                                ? 'bg-primary'
                                : dd?.name === 'UNT Domain (ҰБТ)'
                                ? 'bg-error'
                                : ''
                            } rounded-md px-2 cursor-pointer text-center group
                      w-6 h-6 hover:w-full transition-all duration-500 ease-in-out`}
                          >
                            <div
                              className='hidden group-hover:block opacity-0 hover:opacity-100 
                        transition-all duration-1000 ease-in-out whitespace-nowrap'
                            >
                              {dd?.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </td> */}
                    <td className='text-center border-r px-2 '>
                      <div className='flex flex-row justify-center text-gray-600'>
                        <div
                          className=' bg-sheet-gray rounded-md px-2'
                          style={{ width: 'max-content' }}
                        >
                          {d?.deadline &&
                            format(new Date(d?.deadline), 'dd-MM-yyyy')}
                        </div>
                      </div>
                    </td>
                    <td className=''>
                      {d?.children_count ? (
                        <div className='px-2 text-center'>
                          <div className='text-xs text-gray-600'>{`${d?.done_count}/${d?.children_count}`}</div>
                          <div className='relative h-2 rounded-md bg-sheet-gray'>
                            <div
                              className='h-2 rounded-md bg-primary absolute'
                              style={{
                                width: `${
                                  (d?.done_count / d?.children_count) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : undefined}
                    </td>
                  </>
                )}
              </tr>
              {isOpen.get(d?.uuid) &&
                d?.children?.map((c) => (
                  <tr
                    className='border-b last:border-b-0 hover:bg-gray-100 h-10'
                    key={c?.uuid}
                  >
                    <td className='py-2 border-r px-2 lg:block hidden'>
                      <div className='flex flex-row items-center gap-1 justify-center'>
                        <ChevronDownIcon className='h-4 invisible' />

                        <CalendarIcon className='h-5 text-primary' />
                        {/* <SparklesIcon className='h-5 invisible' /> */}
                      </div>
                    </td>
                    <td className='lg:hidden lg:border-r-0 border-r'>
                      <div className='flex flex-row justify-center items-center'>
                        <Checkbox
                          checked={c?.done}
                          onChange={() => {
                            const body = new FormData();
                            body.append('done', !c?.done);
                            patchRoadmapSubtask(c?.uuid, body)
                              .then(() => {
                                refreshData();
                              })
                              .catch(() => {});
                          }}
                          disabled={d?.status === 'finished'}
                        />
                      </div>
                    </td>
                    <td className='lg:text-base text-sm border-r px-2 lg:min-w-[20rem] lg:max-w-[20rem] lg:w-80 max-w-[2.5rem] w-10'>
                      <EditSub
                        datum={c}
                        refreshData={refreshData}
                        taskStatus={d?.status}
                        className='hover:text-primary px-2 py-1.5 h-full truncate cursor-pointer'
                      />
                    </td>
                    {!isMobile && (
                      <>
                        <td className='font-light lg:text-base text-sm text-center border-r px-2'></td>
                        <td
                          className={`lg:text-base text-sm pl-1 pr-2 w-48 border-r px-2`}
                        ></td>
                      </>
                    )}

                    <td className='text-center border-r px-2'>
                      <div className='flex flex-row justify-center text-gray-600'>
                        <div
                          className=' bg-sheet-gray rounded-md px-2 lg:text-base text-xs'
                          style={{ width: 'max-content' }}
                        >
                          {c?.deadline &&
                            format(new Date(c?.deadline), 'dd-MM-yyyy')}
                        </div>
                      </div>
                    </td>
                    {!isMobile && (
                      <>
                        <td className=''>
                          <div className='flex flex-row justify-center'>
                            <Checkbox
                              checked={c?.done}
                              onChange={() => {
                                const body = new FormData();
                                body.append('done', !c?.done);
                                patchRoadmapSubtask(c?.uuid, body)
                                  .then(() => {
                                    refreshData();
                                  })
                                  .catch(() => {});
                              }}
                              disabled={d?.status === 'finished'}
                            />
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              {d?.status !== 'finished' && isOpen.get(d?.uuid) && (
                <tr
                  className='border-b last:border-b-0 hover:bg-gray-100 h-10'
                  key={index}
                >
                  <td className='py-2 border-r px-2'>
                    <div className='flex flex-row items-center gap-1 justify-center'>
                      <ChevronUpIcon className='h-4 lg:invisible hidden' />
                      <AddSub
                        task={d?.uuid}
                        refreshData={refreshData}
                        studentUuid={studentUuid}
                      />
                      <AddSubAI
                        task={d}
                        taskuuid={d?.uuid}
                        refreshData={refreshData}
                        studentUuid={studentUuid}
                      />
                    </div>
                  </td>

                  {!isMobile && (
                    <>
                      <td className='lg:text-bas text-sm border-r px-2 '></td>
                      <td className='font-light lg:text-base text-sm text-center border-r px-2 '></td>
                      <td
                        className={`lg:text-base text-sm pl-1 pr-2 w-48 border-r px-2`}
                      ></td>
                      <td className='text-center border-r px-2 '></td>
                    </>
                  )}
                  <td className=''></td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
