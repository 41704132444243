import React, { useEffect, useState } from 'react';
import {
  getUniversityInfo,
  getUniversityRatingInfo,
} from '../../services/universities/universityService';
import { useParams } from 'react-router-dom';
import { MapPinIcon } from '@heroicons/react/24/solid';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './University.css';
import General from './Tabs/General';
import Programs from './Tabs/Programs';
import Admission from './Tabs/Admission';
import Cost from './Tabs/Cost';
import Rating from './Tabs/Rating';
import Campus from './Tabs/Campus';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

const University = () => {
  const { key } = useParams();

  const [info, setInfo] = useState();
  const [ratingInfo, setRatingInfo] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [facultiesOpen, setFacultiesOpen] = useState([]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    autoplay: true,
    autoplaySpeed: 3700,
    cssEase: 'ease-out',
  };

  useEffect(() => {
    getUniversityInfo(key).then((res) => {
      setInfo(res);
      console.log('res', res);
      setFacultiesOpen(
        Array.from(res?.eduDegrees, (d) =>
          new Array(d?.faculties?.length).fill(false),
        ),
      );
    });

    getUniversityRatingInfo(key).then((res) => {
      console.log('rating', res);
      setRatingInfo(res);
    });
  }, [key]);

  return (
    <div className='flex flex-col gap-4 h-[90vh] overflow-y-auto px-4'>
      <div className='rounded-xl bg-primary flex flex-col-reverse md:flex-row justify-between text-white'>
        <div className='flex md:flex-col flex-row items-center md:items-start gap-2 md:px-12 px-4 py-4 md:justify-center md:w-2/5'>
          <div
            className='bg-white rounded-lg md:w-48 md:h-28 lg:w-64 lg:h-28 w-24 h-20 
            flex flex-row justify-center shrink-0'
          >
            <img
              src={`https://unitap.org/nova/files/${info?.logo}`}
              alt='logo'
              className='md:w-48 md:h-28 lg:w-48 lg:h-28 w-24 h-20
                 object-contain'
            />
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex flex-row gap-2 items-start'>
              <div className='text-2xl font-semibold md:leading-tight leading-none'>
                {info?.universityName}
              </div>
              {!!info?.universityLink && (
                <div
                  className='text-sky-200 hover:text-sky-300 cursor-pointer'
                  onClick={() => window.open(info?.universityLink, '_blank')}
                >
                  <GlobeAltIcon className='h-6' />
                </div>
              )}
            </div>
            <div className='flex flex-row'>
              <MapPinIcon className='h-5 text-error' />
              {`${info?.city}, ${info?.country?.name}`}
            </div>
          </div>
        </div>
        <div className='md:w-3/5 md:py-8 py-4  md:px-12 px-4'>
          <Slider {...sliderSettings}>
            {info?.gallery?.map((g) => (
              <div key={g} className='h-60 cursor-pointer'>
                <img
                  src={`https://unitap.org/nova/files/${g}`}
                  alt='gallery'
                  className='w-full h-full rounded-lg object-cover'
                />
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <div className='flex flex-col gap-4 w-full'>
        <div className='flex flex-row gap-4 overflow-x-scroll'>
          <div
            className={`leading-tight text-center border rounded-xl px-4 py-3 font-semibold cursor-pointer hover:border-primary ${
              tabIndex === 0 ? 'bg-primary text-white' : 'text-gray-500'
            }`}
            onClick={() => setTabIndex(0)}
          >
            {'Общая информация'}
          </div>
          <div
            className={`border rounded-xl px-4 py-3 font-semibold cursor-pointer hover:border-primary ${
              tabIndex === 1 ? 'bg-primary text-white' : 'text-gray-500'
            }`}
            onClick={() => setTabIndex(1)}
          >
            {'Программы'}
          </div>
          <div
            className={`border rounded-xl px-4 py-3 font-semibold cursor-pointer hover:border-primary ${
              tabIndex === 2 ? 'bg-primary text-white' : 'text-gray-500'
            }`}
            onClick={() => setTabIndex(2)}
          >
            {'Поступление'}
          </div>
          <div
            className={`leading-tight text-center border rounded-xl px-4 py-3 font-semibold cursor-pointer hover:border-primary ${
              tabIndex === 3 ? 'bg-primary text-white' : 'text-gray-500'
            }`}
            onClick={() => setTabIndex(3)}
          >
            {'Цены/Скидки'}
          </div>
          {ratingInfo?.length ? (
            <div
              className={`border rounded-xl px-4 py-3 font-semibold cursor-pointer hover:border-primary ${
                tabIndex === 4 ? 'bg-primary text-white' : 'text-gray-500'
              }`}
              onClick={() => setTabIndex(4)}
            >
              {'Рейтинг'}
            </div>
          ) : undefined}
          <div
            className={`border rounded-xl px-4 py-3 font-semibold cursor-pointer hover:border-primary ${
              tabIndex === 5 ? 'bg-primary text-white' : 'text-gray-500'
            }`}
            onClick={() => setTabIndex(5)}
          >
            {'Кампус'}
          </div>
        </div>
        {tabIndex === 0 ? (
          <General info={info} />
        ) : tabIndex === 1 ? (
          <Programs
            info={info}
            facultiesOpen={facultiesOpen}
            setFacultiesOpen={setFacultiesOpen}
          />
        ) : tabIndex === 2 ? (
          <Admission info={info} />
        ) : tabIndex === 3 ? (
          <Cost info={info} />
        ) : tabIndex === 4 ? (
          <Rating ratingInfo={ratingInfo} />
        ) : tabIndex === 5 ? (
          <Campus info={info} />
        ) : (
          <div className='flex flex-row gap-4'>
            <div className='flex flex-col gap-4'></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default University;
