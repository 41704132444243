import React, { useEffect, useState } from 'react';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import {
  getGradeStats,
  getSchoolsLeaderboard,
  getStudentsStatsBySkills,
} from '../../../services/stats/statsService';
import LeaderboardTable from '../../../components/tables/LeaderboardTable/LeaderboardTable';
import { Button } from '../../../components/forms/Buttons/Button';
import TableOfStudents from './TableOfStudents';

export default function TableOfSchools({
  selectedSchool,
  setSelectedSchool,
  user,
  isAdmin,
}) {
  const [data, setData] = useState([]);
  const [schoolNameToShow, setSchoolNameToShow] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [type, setType] = useState(null);
  const [gradeStats, setGradeStats] = useState([]);
  // const [isAdmin, setIsAdmin] = useState(false);
  const [grade, setGrade] = useState('');

  useEffect(() => {
    setLoadingData(true);

    if (user?.organization) {
      getSchoolsLeaderboard({ page_size: 80 })
        .then((res) => {
          // console.log('skills', res?.results);
          if (res?.results?.length > 1) {
            setData(res?.results);
            setType('schools');
          } else if (res?.results?.length === 1) {
            getGradeStats({
              organization: user?.organization,
            })
              .then((res) => {
                setSchoolNameToShow();
                setData(res?.results);
                setType('grades');
              })
              .catch(() => {})
              .finally(() => setLoadingData(false));
          }
        })
        .catch(() => {})
        .finally(() => setLoadingData(false));
    }
  }, [user]);

  const showStatsForSchool = (organization, schoolName) => {
    setLoadingData(true);
    getGradeStats({ organization: organization })
      .then((res) => {
        if (schoolName) {
          setSchoolNameToShow(schoolName);
        }
        setData(res?.results);
        setType('grades');
        setSelectedSchool(organization);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  };

  const showStatsForGrade = (selectedGrade) => {
    setLoadingData(true);
    getStudentsStatsBySkills({ group: selectedGrade })
      .then((res) => {
        setGradeStats(res?.results);
        setType('students');
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  };

  return (
    <div className='flex flex-col'>
      <div className='flex flex-row gap-2 items-center'>
        {type === 'grades' && isAdmin && (
          <Button
            className='max-h-8'
            text='Back'
            onClick={() => {
              setLoadingData(true);
              setSelectedSchool('all');
              getSchoolsLeaderboard({ page_size: 80 })
                .then((res) => {
                  // console.log('skills', res?.results);
                  setData(res?.results);
                  setType('schools');
                })
                .catch(() => {})
                .finally(() => setLoadingData(false));
            }}
          />
        )}
        <div className='text-xl font-bold leading-tight'>
          {type === 'grades'
            ? `School ${schoolNameToShow ? ': ' + schoolNameToShow : ''}`
            : ''}
        </div>
      </div>
      {loadingData ? (
        <div className='flex flex-col p-8 items-center'>
          <SimpleLoader className={'h-12 w-12'} />
        </div>
      ) : type !== 'students' ? (
        <LeaderboardTable
          data={data}
          type={type}
          showStatsForSchool={showStatsForSchool}
          showStatsForGrade={showStatsForGrade}
          setGrade={setGrade}
        />
      ) : (
        <TableOfStudents
          gradeStats={gradeStats}
          school={selectedSchool}
          showStatsForSchool={showStatsForSchool}
          grade={grade}
        />
      )}
    </div>
  );
}
