import { CheckIcon, ClockIcon, PlayIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import TableOfSchools from './TableOfSchools';

export default function ProjectStats({ overall, data, isAdmin, user }) {
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    if (isAdmin === true && user?.organization) {
      setSelectedSchool('all');
    }

    if (isAdmin === false && user?.organization) {
      setSelectedSchool(user?.organization);
    }

    return () => {};
  }, [isAdmin, user]);

  return (
    <div className='flex flex-col gap-4'>
      <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4 text-white'>
        <div
          className='rounded-xl bg-success bg-opacity-80 flex flex-col 
              gap-2 p-4 justify-between'
        >
          <div className='text-2xl font-bold'>In progress</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {selectedSchool === 'all'
                  ? overall?.inprogress
                  : data?.find((d) => d?.uuid === selectedSchool)?.inprogress}
              </span>{' '}
              projects
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <PlayIcon className='h-6 text-success opacity-80' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-error flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>Waiting</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {selectedSchool === 'all'
                  ? overall?.waiting
                  : data?.find((d) => d?.uuid === selectedSchool)?.waiting}
              </span>{' '}
              projects
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <ClockIcon className='h-6 text-error' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-primary flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>Checking</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {selectedSchool === 'all'
                  ? overall?.checking
                  : data?.find((d) => d?.uuid === selectedSchool)?.checking}
              </span>{' '}
              projects
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <ClockIcon className='h-6 text-primary' />
            </div>
          </div>
        </div>

        <div
          className='rounded-xl bg-success flex flex-col 
              gap-2 p-4 w-full justify-between'
        >
          <div className='text-2xl font-bold'>Accepted</div>
          <div className='flex flex-row justify-between gap-2'>
            <div className='font-semibold'>
              <span className='text-4xl'>
                {selectedSchool === 'all'
                  ? overall?.accepted
                  : data?.find((d) => d?.uuid === selectedSchool)?.accepted}
              </span>{' '}
              projects
            </div>
            <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
              <CheckIcon className='h-6 text-success' />
            </div>
          </div>
        </div>
      </div>

      {/* <div className='rounded-xl border shadow-lg flex flex-col gap-2 p-6'>
        <div>{t('stats.this_year_achievement')}</div>
        <div className='font-bold text-xl'>
          <span className='text-4xl'>
            {selectedSchool === 'all'
              ? overall?.submissions
              : data?.find((d) => d?.uuid === selectedSchool)?.submissions}
          </span>{' '}
          {t(t('stats._submissions'))}
        </div>
      </div>

      <Table selectedSchool={selectedSchool} />
      <div className='border-2 border-gray-400 mt-2'></div> */}
      <TableOfSchools
        selectedSchool={selectedSchool}
        setSelectedSchool={setSelectedSchool}
        user={user}
        isAdmin={isAdmin}
      />
    </div>
  );
}
