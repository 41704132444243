import React, { useContext, useState } from 'react';
import { UserContext } from '../../utils/contexts/UserContext';
import CardBase from './cards/CardBase';

import universityImg from '../../assets/images/university_card.png';
import personalityImg from '../../assets/images/personality_card.png';
import coursesImg from '../../assets/images/courses_card.png';
import projectsImg from '../../assets/images/projects_card.png';
import profileProjectsImg from '../../assets/images/profile_projects_card.png';
import projectSetsImg from '../../assets/images/project_sets_card.png';
import roadmapSmallImg from '../../assets/images/roadmap_small_card.png';
import profileImg from '../../assets/images/profile_card.png';
import meetingImg from '../../assets/images/meeting_card.png';

export default function Home() {
  const { user } = useContext(UserContext);

  const [cardsGroups] = useState([
    {
      header: 'Plan your success',
      description: 'Streamline your academic year with roadmap',
      cards: [
        {
          img: roadmapSmallImg,
          title: 'AI-powered Generation of Roadmap',
          text: 'Make a plan for the school year and track your progress in timeline chart. You can create or generate tasks with subtasks together with AI. Each task customizable by you or your mentor.',
          link: '/roadmap',
        },
        {
          img: profileImg,
          title: `Complete student's portfolio on Profile page`,
          text: '• Preferred Pairs \n• Progress indicators \n• Academic performance(BTS, Olympiad) \n• Ongoing Project',
          link: `/profile/${user?.uuid}`,
        },
        {
          img: meetingImg,
          title: 'Meetings: сollaboration of students and teachers',
          text: 'Meetings are designed to make collaboration effortless. Easily create and participate in virtual meetings between students and teachers, fostering seamless communication and teamwork.',
          link: '/video-conference',
        },
      ],
    },
    {
      header: 'Career guidance',
      description: 'Empower your future: Discover, Learn, Achieve',
      cards: [
        {
          img: universityImg,
          title: 'University admissions guide \nacross the world',
          text: 'Detailed information on over 300 global institutions and get guided through every step of the admissions process. Find your perfect match with advanced search filters',
          link: '/universities',
        },
        {
          img: personalityImg,
          title: 'Discover your personal strengths and soft skills',
          text: '• MBTI, MIS tests \n• Suitable professions based on test results \n• Download personal reports',
          link: '/tests',
        },
        {
          img: coursesImg,
          title: 'Improve your skills with \nCourses',
          text: 'Courses designed to facilitate learning and knowledge acquisition. Each course is divided into sections. These sections are further divided into lessons, which contain various types of materials.',
          link: '/courses',
        },
      ],
    },
    {
      header: 'Inspire with project',
      description:
        'Сomplete projects, create projects, collect projects into a playlist. You can do all of this on our platform.',
      cards: [
        {
          img: projectsImg,
          title: 'Earn points for completing projects',
          text: 'In the project table, you can find a project that suits you. Completing these projects will earn you points in indicators. We also consider your achievements from bil.org.kz',
          link: '/projects',
        },
        {
          img: profileProjectsImg,
          title: 'Project submission process',
          text: 'In Progress: The student is currently working on the project.\nSubmitted: The student submitted the project, but can still make changes.\nChecking: The project is under review by the teacher.\nAccepted/declined: The project has been reviewed and, then approved or rejected.',
          link: `/profile/${user.uuid}`,
          state: { tab: 'projects' },
        },
        {
          img: projectSetsImg,
          title: 'Projects sets for practical learning',
          text: 'Set of projects that help students apply their knowledge and skills in practical ways, aligned with their educational curriculum. You can also create your own set.',
          link: '/study-plans',
        },
      ],
    },
  ]);

  return (
    <div className='flex flex-col py-4 lg:px-28 md:px-14 px-4  gap-2'>
      {cardsGroups.map((cardGroup, index) => (
        <React.Fragment key={index}>
          {!!index && <div className='md:mt-16 mt-8' />}
          <span className='font-black text-4xl text-blue-600'>
            {cardGroup?.header}
          </span>
          <span className='text-lg'>{cardGroup?.description}</span>

          <div className='grid md:grid-cols-3 grid-cols-1 md:gap-8 gap-4'>
            {cardGroup?.cards?.map((card, i) => {
              return (
                <CardBase
                  key={i}
                  title={card.title}
                  img={card.img}
                  text={card.text}
                  link={card.link}
                  state={card?.state}
                  cols={card?.cols}
                />
              );
            })}
            {cardGroup?.custom_cards && (
              <cardGroup.custom_cards></cardGroup.custom_cards>
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
