import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/forms/Buttons/Button';
import {
  getRoadmapDirections,
  postRoadmapSurvey,
} from '../../../services/roadmapTask/roadmapCreateService';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import { toast } from 'react-toastify';

export default function SurveyPart({
  setSelectedIndex,
  comment,
  selectedStudent,
  userSurveyResponse,
  setUserSurveyResponse,
  setTimePeriod,
  timePeriod,
  setDraftRoadmapUuid,
}) {
  const [isManualQuestions, setIsManualQuestions] = useState(true);
  const [roadmapDirections, setRoadmapDirections] = useState([]);
  const [selectedDirections, setSelectedDirections] = useState([]);
  const [selectedMainDirections, setSelectedMainDirection] = useState([]);
  const [questionsLoading, setQuestionsLoading] = useState(false);

  const [generatedQuestions, setGeneratedQuestion] = useState(null);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    getRoadmapDirections().then((res) => {
      setRoadmapDirections(res?.results);
    });

    return () => {};
  }, []);

  // Function to handle answer selection
  const handleAnswerChange = (questionIndex, answer, question) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = answer;
    setAnswers(updatedAnswers);

    const updatedResponse = [...userSurveyResponse];
    updatedResponse[questionIndex] = `${question} ${answer?.join(', ')}`;
    setUserSurveyResponse(updatedResponse);
  };

  return (
    <div className='flex flex-row justify-center'>
      <div className='p-12 w-1/2 shadow-md rounded-md border'>
        {isManualQuestions ? (
          <div className='flex flex-col'>
            {/* Question 1 */}
            <div className='mb-6'>
              <p className='mb-2 font-semibold'>
                Please select the direction you would like this roadmap to
                follow:
              </p>
              <div className='flex flex-col'>
                {roadmapDirections?.map((dir, index) => (
                  <React.Fragment>
                    <label key={dir?.uuid} className='mb-1'>
                      <input
                        required
                        type='checkbox'
                        name='question1'
                        value={dir?.uuid}
                        checked={selectedDirections?.includes(dir?.uuid)}
                        onChange={(e) => {
                          setSelectedMainDirection((prev) => {
                            if (!e.target.checked) {
                              return prev?.filter((v) => v !== dir?.uuid);
                            } else if (prev?.length >= 2) {
                              return prev;
                            }
                            return [...prev, dir?.uuid];
                          });
                          setSelectedDirections((prev) => {
                            if (!e.target.checked) {
                              return prev?.filter(
                                (v) =>
                                  ![dir?.uuid, ...dir.subdirections]?.includes(
                                    v,
                                  ),
                              );
                            } else if (selectedMainDirections?.length >= 2) {
                              return prev;
                            }
                            return [...prev, dir?.uuid];
                          });
                        }}
                        className='mr-2'
                      />
                      {dir?.name}
                    </label>
                    {selectedDirections?.includes(dir?.uuid) &&
                      dir.subdirections?.map((subDir) => (
                        <label key={subDir?.uuid} className='ml-4 mb-1'>
                          <input
                            required
                            type='checkbox'
                            name='question1'
                            value={subDir?.uuid}
                            checked={selectedDirections?.includes(subDir?.uuid)}
                            onChange={(e) => {
                              setSelectedDirections((prev) => {
                                if (!e.target.checked) {
                                  return prev?.filter((v) => v !== subDir.uuid);
                                }
                                return [...prev, subDir?.uuid];
                              });
                            }}
                            className='mr-2'
                          />
                          {subDir?.name}
                        </label>
                      ))}
                  </React.Fragment>
                ))}
              </div>
            </div>

            {/* Question 2 */}
            <div className='mb-6'>
              <p className='mb-2 font-semibold'>
                What time frame should this roadmap cover?
              </p>
              <div className='flex flex-row gap-2'>
                <input
                  type='number'
                  required
                  min={1}
                  max={6}
                  onChange={(e) => setTimePeriod(e.target.value)}
                  className='px-2 border-b-2 focus:outline-none bg-gray-100 rounded-t-md w-12'
                />
                {/* <Select  /> */}
                <span className='pt-2'>months</span>
              </div>
            </div>
          </div>
        ) : !questionsLoading ? (
          <div className='flex flex-col'>
            {/* Question 1 */}
            {generatedQuestions?.response?.questions?.questions?.map(
              (q, idx) => (
                <div key={idx} className='mb-4'>
                  <p className='mb-2 font-semibold'>{q?.question}</p>
                  <div className='flex flex-col'>
                    {[q?.a, q?.b, q?.c, q?.d].map((option, index) => (
                      <>
                        {option && (
                          <label key={index} className='mb-1'>
                            <input
                              type='checkbox'
                              name={q?.question}
                              value={option}
                              checked={answers[idx]?.includes(option)}
                              onChange={(e) => {
                                let newAnswers = answers[idx]
                                  ? [...answers[idx]]
                                  : [];

                                if (!e.target.checked) {
                                  newAnswers = newAnswers?.filter(
                                    (v) => v !== option,
                                  );
                                } else {
                                  newAnswers = [...newAnswers, option];
                                }
                                handleAnswerChange(
                                  idx,
                                  newAnswers,
                                  q?.question,
                                );
                              }}
                              className='mr-2'
                            />
                            {option}
                          </label>
                        )}
                      </>
                    ))}
                  </div>
                </div>
              ),
            )}
          </div>
        ) : (
          <div className='min-h-[calc(100vh-24rem)] flex flex-col justify-center'>
            <SimpleLoader className='h-12' />
          </div>
        )}
        <div className='flex flex-row justify-end px-6'>
          <Button
            disabled={questionsLoading}
            loading={questionsLoading}
            onClick={() => {
              setQuestionsLoading(true);
              if (isManualQuestions) {
                if (!selectedDirections?.length || !timePeriod) {
                  toast.error('Direction and time period is required');
                  setQuestionsLoading(false);

                  return;
                }
                const data = {
                  student_info: comment,
                  time_period: timePeriod + ' month',
                  directions: selectedDirections?.join(','),
                };
                const body = {
                  data: data,
                  student_uuid: selectedStudent?.uuid,
                };
                postRoadmapSurvey(body)
                  .then((res) => {
                    console.log('res', res);
                    setGeneratedQuestion(res);
                    setIsManualQuestions(false);
                  })
                  .finally(() => {
                    setQuestionsLoading(false);
                  });
              } else if (generatedQuestions?.conversation_uuid) {
                const data = {
                  student_info: comment,
                  time_period: timePeriod + ' month',
                  directions: selectedDirections?.join(','),
                };

                const user_response = userSurveyResponse?.join(' // ');

                const body = {
                  conversation_uuid: generatedQuestions?.conversation_uuid,
                  user_response,
                  data: data,
                  student_uuid: selectedStudent?.uuid,
                };
                postRoadmapSurvey(body)
                  .then((res) => {
                    console.log('res', res);
                    if (res?.response?.confidence === 'HIGH') {
                      setSelectedIndex((v) => v + 1);
                      setAnswers([]);

                      setDraftRoadmapUuid(res?.response?.draft_roadmap_uuid);
                      // getRoadmap(res?.response?.draft_roadmap_uuid_);
                    } else {
                      setGeneratedQuestion(res);
                    }
                  })
                  .finally(() => {
                    setQuestionsLoading(false);
                  });
              }
            }}
            text='Next'
          ></Button>
        </div>
      </div>
    </div>
  );
}
