import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MagnifyingGlassIcon, MapPinIcon } from '@heroicons/react/24/solid';
import {
  getUniversities,
  getUniversityCountries,
} from '../../services/universities/universityService';
import Checkbox from '../../components/forms/Checkbox/Checkbox';
import SimpleLoader from '../../components/Loader/SimpleLoader';
import Select from '../../components/forms/Select/Select';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import MobileSidebarModal from '../../components/modals/MobileSidebarModal/MobileSidebarModal';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { SidebarContext } from '../../utils/contexts/SidebarContext';

const PAGE_SIZE = 12;

const Universities = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [top100, setTop100] = useState();
  const [top200, setTop200] = useState();
  const [top300, setTop300] = useState();
  const [fee10, setFee10] = useState(false);
  const [fee20, setFee20] = useState(false);
  const [fee30, setFee30] = useState(false);
  const [fee40, setFee40] = useState(false);
  const [funding, setFunding] = useState();
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [page, setPage] = useState(1);
  const [N, setN] = useState(0);
  const [search, setSearch] = useState();
  const { setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const getData = useCallback(() => {
    setLoadingData(true);

    getUniversities({
      size: PAGE_SIZE,
      page: 1,
      fullScholarship: funding || null,
      country,
      search: search ?? searchParams.get('search'),
      ratings: `${top100 ? 'TOP_100,' : ''}${top200 ? 'TOP_200,' : ''}${
        top300 ? 'TOP_500,' : ''
      }`.slice(0, -1),
      fee: `${fee10 ? 'FEE_10,' : ''}${fee20 ? 'FEE_20,' : ''}${
        fee30 ? 'FEE_30,' : ''
      }${fee40 ? 'FEE_40,' : ''}`.slice(0, -1),
    })
      .then((res) => {
        setData(res?.data);
        setN(res?.totalCount);
        setPage(1);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  }, [
    searchParams,
    funding,
    country,
    search,
    top100,
    top200,
    top300,
    fee10,
    fee20,
    fee30,
    fee40,
  ]);

  useEffect(() => {
    if (search === null && searchParams.get('search')) {
      setSearch(searchParams.get('search'));
    }

    getData();

    setLoadingCountries(true);
    getUniversityCountries()
      .then((res) => {
        setCountries(res);
      })
      .finally(() => setLoadingCountries(false));
  }, [getData, search, searchParams]);

  const getMoreData = () => {
    if (loadingMoreData || data?.length >= N) return;
    setLoadingMoreData(true);
    getUniversities({
      size: PAGE_SIZE,
      page: page + 1,
      fullScholarship: funding,
      country,
      search: search ?? searchParams.get('search'),
      ratings: `${top100 ? 'TOP_100,' : ''}${top200 ? 'TOP_200,' : ''}${
        top300 ? 'TOP_500,' : ''
      }`.slice(0, -1),
      fee: `${fee10 ? 'FEE_10,' : ''}${fee20 ? 'FEE_20,' : ''}${
        fee30 ? 'FEE_30,' : ''
      }${fee40 ? 'FEE_40,' : ''}`.slice(0, -1),
    })
      .then((res) => {
        setData((prev) => [...prev, ...res?.data]);
        setPage((prev) => prev + 1);
      })
      .catch(() => {})
      .finally(() => setLoadingMoreData(false));
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight >= scrollHeight - 200) {
      getMoreData();
    }
  };

  const FilterComponent = () => (
    <div className='flex flex-col w-56 shrink-0 sticky gap-4 border-r-2'>
      <div className='flex flex-col gap-2 border-b-2 pb-3'>
        {!isMobile && <p className='font-medium'>Filter</p>}
        <div className='pr-2'>
          {/* <InputWithIcon
      Icon={MapPinIcon}
      placeholder={'Country'}
      onChange={(e) => setCountry(e.target.value)}
      value={country}
      classnameIcon={'text-primary'}
    /> */}
          <Select
            options={
              countries?.length
                ? [
                    { label: 'All countries', id: 'all' },
                    ...countries.map((c) => ({
                      label: c?.name,
                      value: c?.id,
                    })),
                  ]
                : [{ label: 'Not available', id: 'all' }]
            }
            placeholder={'Select a country'}
            value={country}
            onChange={(e) => setCountry(e?.value)}
            Icon={MapPinIcon}
            loading={loadingCountries}
          />
        </div>
      </div>
      <div className='flex flex-col gap-2 border-b-2 pb-3'>
        <p className='font-medium'>Rating</p>
        <Checkbox
          label={'Top 100'}
          onChange={() => {
            if (top100) setTop100(null);
            else setTop100('TOP_100');
          }}
          checked={top100 === 'TOP_100'}
        />
        <Checkbox
          label={'Top 101-300'}
          onChange={() => {
            if (top200) setTop200(null);
            else setTop200('TOP_200');
          }}
          checked={top200 === 'TOP_200'}
        />
        <Checkbox
          label={'Top 301-500'}
          onChange={() => {
            if (top300) setTop300(null);
            else setTop300('TOP_500');
          }}
          checked={top300 === 'TOP_500'}
        />
      </div>
      <div className='flex flex-col gap-2 border-b-2 pb-3'>
        <p className='font-medium'>Type of scholarship</p>
        <Checkbox
          label={'Full funding'}
          onChange={() => setFunding((prev) => !prev)}
          checked={funding}
        />
      </div>
      <div className='flex flex-col gap-2 border-b-2 pb-3'>
        <p className='font-medium'>Price per year</p>
        <Checkbox
          label={'up to $10 000'}
          onChange={() => setFee10((prev) => !prev)}
          checked={fee10}
        />
        <Checkbox
          label={'from $10 000 to $20 000'}
          onChange={() => setFee20((prev) => !prev)}
          checked={fee20}
        />
        <Checkbox
          label={'from $20 000 to $40 000'}
          onChange={() => setFee30((prev) => !prev)}
          checked={fee30}
        />
        <Checkbox
          label={'from $40 000'}
          onChange={() => setFee40((prev) => !prev)}
          checked={fee40}
        />
      </div>
    </div>
  );

  return (
    <div className='flex flex-row h-[85vh]'>
      {isMobile && (
        <div className=' w-full fixed bottom-8 flex flex-row justify-center'>
          <div
            className='rounded-3xl bg-primary text-white px-4 py-2
          flex flex-row items-center gap-1'
            onClick={() => setId(uuid)}
          >
            <p>Filter</p>
            <AdjustmentsHorizontalIcon className='h-4' />
          </div>
        </div>
      )}
      {isMobile ? (
        <div>
          <MobileSidebarModal
            headerText={'Filter'}
            uuid={uuid}
            onClose={() => setId('')}
          >
            <FilterComponent />
          </MobileSidebarModal>
        </div>
      ) : (
        <div className=''>
          <FilterComponent />
        </div>
      )}
      <div
        onScroll={handleScroll}
        className='flex flex-col lg:text-sm md:text-sm text-xs 
        h-[90vh] overflow-y-auto w-full px-2'
      >
        <div className='sticky top-0 md:px-8 px-2 pb-2 flex flex-row justify-center w-full'>
          {/* <InputWithIcon
            Icon={MagnifyingGlassIcon}
            defaultValue={search ?? searchParams.get('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={'Univerity name or specialty'}
          /> */}
          <div className='relative rounded-md shadow-sm group'>
            <div
              className={`${'cursor-pointer'} absolute inset-y-0 left-0 flex items-center pl-2`}
            >
              <span>
                <MagnifyingGlassIcon
                  className={`h-6 group-hover:text-primary`}
                />
              </span>
            </div>
            <input
              style={{ fontSize: isMobile ? '1.1rem' : '1.25rem' }}
              type={'text'}
              defaultValue={search ?? searchParams.get('search')}
              className='block h-12 md:w-[36rem] w-full rounded-md border-0 py-2 pl-10 pr-20 text-gray-900 ring-1 ring-inset 
              ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset 
              focus:ring-primary focus:outline-none sm:text-sm sm:leading-6 group-hover:ring-primary'
              placeholder={'University or speciality'}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        <div className='flex flex-row gap-2 justify-center pb-2 md:text-lg text-base'>
          <p>Found</p>
          <div className='font-semibold'>{`${N ?? '0'} ${
            N === 1 ? 'university' : 'universities'
          }`}</div>
        </div>
        <div className='flex flex-wrap justify-center gap-x-6 gap-y-4'>
          {loadingData ? (
            <div className='pt-6'>
              <SimpleLoader className={'w-12 h-12'} />
            </div>
          ) : !data?.length ? (
            <div className='w-full flex flex-row justify-center text-gray-500 py-8 text-lg'>
              {/* No universities */}
            </div>
          ) : (
            data?.map((d) => (
              <div
                key={d?.id}
                style={{ borderColor: d?.color }}
                className='rounded-lg border flex flex-col justify-between
                md:w-64 md:h-96 lg:w-64 lg:h-[22rem] w-[40vw] h-72 cursor-pointer
                hover:border-2 hover:shadow-xl'
                onClick={() => window.open(`/universities/${d?.id}`, '_blank')}
              >
                <div className='md:w-48 md:h-40 lg:w-48 lg:h-40 h-20 p-2 m-auto'>
                  <img
                    src={`https://unitap.org/nova/files/${d?.logo}`}
                    alt='logo'
                    className='md:w-48 md:h-40 lg:w-48 lg:h-40 w-full h-full
                  rounded-full object-contain'
                  />
                </div>
                <div className='flex flex-col justify-between px-4 py-2 h-full'>
                  <div className='flex flex-col gap-2 h-full justify-between'>
                    <div className='font-medium md:text-base text-sm leading-tight tracking-wide'>
                      {d?.universityName}
                    </div>
                    <div
                      className='text-gray-500 max-h-20 text-ellipsis overflow-hidden 
                      md:leading-normal leading-[1.1]'
                    >
                      <span style={{ color: d?.color }}>
                        {d?.ratingInformation?.[0]?.information}
                      </span>{' '}
                      {d?.ratingInformation?.[0]?.name}
                    </div>
                    <div
                      style={{ color: d?.color }}
                      className='font-medium md:text-base text-sm'
                    >{`from $ ${d?.cost}`}</div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      {/* {loadingMoreData && (
        <div>
          <SimpleLoader className={'w-12 h-12'} />
        </div>
      )} */}
    </div>
  );
};

export default Universities;
