import { PlusSmallIcon, SparklesIcon } from '@heroicons/react/24/outline';
import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/forms/Buttons/Button';
import Input from '../../../components/forms/Inputs/InputBil';
import TextArea from '../../../components/forms/Inputs/TextArea';
import { toast } from 'react-toastify';
import { postRoadmapSubtaskAi } from '../../../services/roadmapTask/roadmapSubTaskAiService';
import Select from '../../../components/forms/Select/Select';
import { SidebarContext } from '../../../utils/contexts/SidebarContext';
import SidebarModal from '../../../components/modals/SidebarModal/SidebarModal';

const AddSubAI = ({ task, taskuuid, refreshData }) => {
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [description, setDescription] = useState('');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [domainOptions, setDomainOptions] = useState([]);

  const reset = useCallback(() => {
    setDescription('');
    setSelectedStatus(null);
  }, []);

  const onClose = () => {
    reset();
    setId('');
  };

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid, reset]);

  useEffect(() => {
    if (task?.domains) {
      const options = task?.domains?.reduce((acc, domain) => {
        if (domain?.childs) {
          domain.childs.forEach((child) => {
            acc.push({ label: child.name, value: child.name });
          });
        }
        return acc;
      }, []);
      setDomainOptions(options);
    }
  }, [task]);

  return (
    <>
      <SparklesIcon
        className='lg:h-5 h-4 text-warning cursor-pointer'
        onClick={() => {
          setId(uuid);
        }}
      />
      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_subTask_withAi')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          {task?.domains[0]?.childs.length > 0 ? (
            <div>
              <Select
                placeholder={'something'}
                isSearchable={false}
                isClearable={true}
                value={selectedStatus}
                options={domainOptions}
                onChange={(e) => {
                  setSelectedStatus(e?.value);
                }}
              />
            </div>
          ) : null}

          <Input label={'Number of Subtasks'} value={''} />

          <Input label={'Days'} value={''} />

          <TextArea
            label={t('projects.description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('roadmap.add')}
              loading={loadingSubmit}
              onClick={() => {
                const body = new FormData();
                body.append('task_id', taskuuid);
                body.append('user_input', description);

                setLoadingSubmit(true);
                postRoadmapSubtaskAi(body)
                  .then((res) => {
                    toast.success('Subtask added successfully with AI');
                    refreshData();
                    onClose();
                  })
                  .catch((err) => {
                    toast.error('Error adding subtask');
                  })
                  .finally(() => {
                    setLoadingSubmit(false);
                  });
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default AddSubAI;
